.app-navbar {
  /*background-color: var(--bs-purple);
  color: var(--bs-white);*/
}

.navbar-nav a {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.navbar-light .navbar-brand {
  color: #000;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
}

.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
  color: #000;
}

.navbar-light .navbar-nav .nabar-link {
  color: #000;
}

