.featurette-divider {
  margin: 5rem 0;
}

.carousel-item {
    height: 100vh;
    min-height: 300px;
  }
  
  .carousel-caption {
    bottom: 220px;
    z-index: 2;
  }
  
  .carousel-caption h5 {
    font-size: 45px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 25px;
  }
  
  .carousel-caption p {
    width: 60%;
    margin: auto;
    font-size: 18px;
    line-height: 1.9;
  }
  
  .carousel-inner::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.7);
    z-index: 1;
  }

  .card i {
    font-size: 40px;
  }