@import url("https://fonts.googleapis.com/css?family=Montserrat");

* {
  font-family: montserrat;
}

body {
  background: #f1fbff;
}

.section-padding {
  padding: 100px 0;
}

.w-100 {
  height: 100vh;
}

@media only screen and (max-width: 768px) and (max-width: 991px) {
  .carousel-caption {
    bottom: 370px
  }
  .carousel-caption p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .navbar-nav {
    text-align: center;
  }
  .carousel-caption {
    bottom: 125px
  }
  .carousel-caption h5 {
    font-size: 17px;
  }
  .carousel-caption a {
    padding: 10px 15px;
  }

  .carousel-caption p {
    width: 100%;
    line-height: 1.6;
    font-size: 12px;
  }
  
}